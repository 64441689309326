<template>
    <div class="root-wrap">
        <div class="body-wrap">
            <div class="fill-box">
                <div class="bg">
                    <img :src="require('@/assets/images/order/pay-bg.png')" style="width: 100%;height: 150px;">
                </div>
            </div>
            <div class="pay-wrap">
                <div class="status-bar">
                    <div class="status-title">
                        <van-icon :name="statusIcon" style="margin-right: 10px;"></van-icon>{{order.orderStatusText}}
                    </div>
                </div>
                <div class="block">
                    <div class="fx-row">
                        <div style="margin-right: 20px;">
                            <img :src="order.logo" style="width: 60px;height: 60px;">
                        </div>
                        <div class="fx-column fx-between" style="padding: 8px 0;">
                            <div class="product-name">{{order.orderTitle}}</div>
                            <div class="product-origin">官方价&nbsp;￥{{order.officialPrice}}</div>
                        </div>
                    </div>
                </div>
                <div class="block">
                    <div class="order-detail">
                        <div class="detail-name">充值账号</div>
                        <div class="detail-value"><span>{{order.chargeAccount}}</span><span v-if="order.chargeAccountAppend">{{ '，' + order.chargeAccountAppend }}</span></div>
                    </div>
                    <van-divider style="margin: 16px 0;" />
                    <div class="order-detail">
                        <div class="detail-name">下单编号</div>
                        <div class="detail-value">
                            <span>{{order.id}}</span>
                        </div>
                    </div>
                    <div class="order-detail" style="margin-top: 10px;">
                        <div class="detail-name">下单时间</div>
                        <div class="detail-value">
                            <span>{{order.addTime}}</span>
                        </div>
                    </div>
                    <div v-if="order.orderStatus == 'T'" style="margin-top: 10px; font-weight: 700;">30-60分钟到账，超时未到账请拨打客服电话：400-6060-115</div>
                </div>
                <div class="block" v-if="order.refundReason && order.orderStatus == 'R'">
                    <div class="order-detail">
                        <div class="detail-name">退款原因</div>
                        <div class="detail-value"><span class="fc-active">{{order.refundReason}}</span></div>
                    </div>
                </div>
                <div class="block">
                    <div class="order-detail detail-grid">
                        <div class="detail-name">商品总额</div>
                        <div class="detail-value"><span class="fc-status" style="font-weight: 700;">￥{{order.amount}}</span></div>
                    </div>
                    <div v-if="order.needSmsCode == 'Y'" style="margin-top: 10px;">
                        <van-field v-model="verifyCode" required type="textarea" label="验证码" placeholder="请输入接收的验证码" label-width="4em" class="input" :readonly="!update" ref="input" :autosize="{maxHeight: '180'}">
                            <template #button>
                                <van-button v-if="!update" style="color: #333 !important; background-color: #eff1f8" size="small" @click="updateVerifyCode">修改</van-button>
                                <van-button v-else size="small" type="primary" @click="saveVerifyCode">保存</van-button>
                            </template>
                        </van-field>
                        <!-- <van-cell>
                            <input slot="title" class="input" placeholder="请输入接收的验证码">
                        </van-cell> -->
                        <div style="white-space: pre-line;margin-top: 10px;color: #82848a;" v-html="order.needSmsCodeDesc">
                        </div>
                    </div>
                </div>
                <div class="block" v-if="order.note">
                    <div class="order-detail" style="align-items: start;margin-top: 10px;">
                        <div class="detail-name">注意事项</div>
                        <div class="detail-value" v-html="order.note" style="font-size: 10px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getOrderDetail, saveSmsCode } from '@api/recharge-request'

export default {
    data() {
        return {
            on: '',
            order: {},
            update: false,
            verifyCode: '',
        }
    },
    computed: {
        statusIcon() {
            if (this.order.orderStatus == 'N') return 'clock-o';
            if (this.order.orderStatus == 'T') return 'gold-coin-o';
            if (this.order.orderStatus == 'S') return 'passed-o';
            return 'clock-o'

        }
    },
    created() {
        this.on = this.$route.query.on;
        this.getOrderDetail();
    },
    methods: {
        getOrderDetail() {
            getOrderDetail(this.on).then((res) => {
                this.order = res;
                res.smsCode ? this.verifyCode = res.smsCode : '';
            })
        },
        saveVerifyCode() {
            this.update = false;
            let params = {
                orderId: this.on,
                smsCode: this.verifyCode
            }
            saveSmsCode(params).then((res) => {
                console.log(res)
                this.$toast('操作成功')
            })
        },
        updateVerifyCode() {
            this.update = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	flex: 1;
	position: relative;
	.bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 0;
	}
}
.pay-wrap {
	// position: absolute;
	// top: -110px;
	width: 100%;
	position: relative;
	z-index: 9;
	margin-top: 20px;
}

.status-bar {
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	/deep/ .van-count-down {
		line-height: 1;
	}

	.status-title {
		font-size: 18px;
	}

	.status-time {
		font-size: 14px;
	}
}

.bg {
	position: relative;
}

.block {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 1px 2px 10px rgba(27, 26, 25, 0.2);
	margin: 12px;
	padding: 16px 10px;
	.order-detail {
		display: grid;
		grid-template-columns: 70px auto;
		align-items: center;
	}
	.detail-name {
		color: $tips-color;
		font-size: 13px;
	}

	.detail-value {
		color: #333333;
		font-size: 13px;
		/deep/ p {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.copy {
		display: inline-block;
		background-color: $bg-color;
		padding: 2px 5px;
		font-size: 12px;
		font: 500 3.2vw/4vw 微软雅黑;
		margin-left: 20px;
		border-radius: 999px;
	}

	.detail-grid {
		display: grid;
		grid-template-columns: repeat(2, auto);
		justify-content: space-between;
		grid-row-gap: 8px;
		.detail-name {
			color: $tips-color;
			font-size: 13px;
		}

		.detail-value {
			color: #333333;
			font-size: 13px;
			text-align: right;
		}
	}

	.product-name {
		font-size: 14px;
	}

	.product-origin {
		color: $tips-color;
		text-decoration: line-through;
	}

	.input {
		// width: 100%;\
		// display: flex;
		// align-items: center;
		/deep/ textarea {
			background-color: $bg-light-color;
			font-size: 14px;
			border: 0;
			border-radius: 4px;
			padding: 6px 10px;
			&::placeholder {
				font-size: 12px;
			}
		}
	}
}

.footer-wrap {
	background-color: #fff;
	padding: 12px;
	.action {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
}
</style>
